import "isomorphic-fetch"
import { API_HOST_BASE } from '../constants/env';

const pluginAPIBase = `${API_HOST_BASE}/outlook/plugin`;

export const sendOtp = (email) => {
    return fetch(`${pluginAPIBase}/send_otp`, {
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
        body: JSON.stringify({email})
    }).then(response => response.json())
}

export const verifyOtp = (email, otp, userToken) => {
    return fetch(`${pluginAPIBase}/verify_otp`, {
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 'Token': userToken},
        body: JSON.stringify({email, otp})
    }).then(response => response.json())
}

export const getUserData = (userToken) => {
    return fetch(`${pluginAPIBase}/user_data`, {
        method: "GET",
        headers: {'Token': userToken}
    }).then(response => response.json())
}

export const signOut = (userToken) => {
    return fetch(`${pluginAPIBase}/signout`, {
        method: "GET",
        headers: {'Token': userToken}
    }).then(response => response.json())
}