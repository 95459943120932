import * as React from 'react';
import RecruitmentMessageBuilder from './RecruitmentMessageBuilder';
import { PrimaryButton } from 'office-ui-fabric-react';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { insertSuggestion } from '../../../utils/officeUtil';
import { buildRecruitmentMessageTemplate } from '../../../utils/messageBuilderUtil';
import { updateCurrentStep } from '../../../actions';
import {
    STEP_RECRUITMENT_INTRO,
    STEP_RECRUITMENT_MAILING_LIST_INTRO,
    STEP_RECRUITMENT_BUILDER_INTRO, 
    STEP_RECRUITMENT_COMPLETE,
    STEP_RECRUITMENT_GENERATE_MESSAGE,
    STEP_RECRUITMENT_PRE_COMPLETE
} from '../../../constants';
import '../../../styles/RecruitmentLayout.scss';
import { connect } from 'react-redux';
import EvieLayover from '../common/EvieLayover';
import EvieCallout from '../common/EvieCallout';
import { signOut } from '../../../utils/userDataUtil';
import MailingListHelp from '../common/MailingListHelp';

class RecruitmentLayout extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            messageBuilderData: null
        };
        this.generateMessageButtonRef = React.createRef();
        this.mailingListRef = React.createRef();
    }

    onMessageDataChanged = (updatedData) => {
        this.setState({messageBuilderData: updatedData});
    }

    generateMessage = () => {
        ga('send', 'event', 'rec_generate_message', 'click');
        const messageTemplate = buildRecruitmentMessageTemplate(this.state.messageBuilderData);
        insertSuggestion(messageTemplate, Office.CoercionType.Html);
        if (this.props.currentOnboardingStep === STEP_RECRUITMENT_GENERATE_MESSAGE) {
            this.props.dispatch(updateCurrentStep({step: STEP_RECRUITMENT_PRE_COMPLETE}));
        }
    }

    introLayoutProps = () => {
        const userName = Office.context.mailbox.userProfile.displayName;
        return {
            header: `Hi, ${userName}! <span class='wave'>👋</span>`,
            welcomeMessage: "Let me show you how easy it is to get me to schedule interviews on your behalf.",
            actionPrimary: () => this.props.dispatch(updateCurrentStep({step: STEP_RECRUITMENT_MAILING_LIST_INTRO})),
            actionSecondary: () => {
                ga('send', 'event', 'recruitment_tutorial', 'skipped');
                this.props.dispatch(updateCurrentStep({step: STEP_RECRUITMENT_COMPLETE}))
            },
        }
    }

    preCompleteLayoutProps = () => {
        return {
            header: "Awesome!",
            subHeader: "I just populated the message into your email body and cc'd myself",
            welcomeMessage: "You can directly edit the message - otherwise, you're good to go!",
            actionPrimary: () => this.props.dispatch(updateCurrentStep({step: STEP_RECRUITMENT_COMPLETE})),
            textPrimary: "Done"
        }
    }

    showTutorial = () => {
        ga('send', 'event', 'recruitment_tutorial', 'replayed');
        this.props.dispatch(updateCurrentStep({step: STEP_RECRUITMENT_INTRO}));
    }

    getCalloutProps = () => {
        switch (this.props.currentOnboardingStep) {
            case STEP_RECRUITMENT_MAILING_LIST_INTRO:
                    return {
                        description: <span>
                                        Add required interviewers to the To: field and cc the optional interviewers.
                                    </span>,
                        target: this.mailingListRef,
                        action: () => this.props.dispatch(updateCurrentStep({step: STEP_RECRUITMENT_BUILDER_INTRO}))
                    };
            case STEP_RECRUITMENT_GENERATE_MESSAGE:
                return {
                    description: <span><h4>Great!</h4><br/>You’ve got all the details down! Hit this button to populate the message into your email body.</span>,
                    target: this.generateMessageButtonRef
                };
        }
    }

    onFaqClick = () => {
        ga('send', 'event', 'faq_link', 'click');
        window.open("https://evie.ai/faq", '_blank');
    }

    render() {
        const canGenerateMessage = this.state.messageBuilderData && this.state.messageBuilderData.candidates.length > 0;
        const { currentOnboardingStep } = this.props;

        return (
            <React.Fragment>
                {currentOnboardingStep === STEP_RECRUITMENT_INTRO && <EvieLayover layoutData={this.introLayoutProps()} />}
                {currentOnboardingStep === STEP_RECRUITMENT_PRE_COMPLETE && <EvieLayover layoutData={this.preCompleteLayoutProps()} />}
                <div className='recruitment-main'>
                    <div ref={this.mailingListRef}><MailingListHelp inviteeType={"interviewers"} /></div>
                    <RecruitmentMessageBuilder onMessageDataChanged={this.onMessageDataChanged} />
                    <div ref={this.generateMessageButtonRef} className="generate-message">
                        <PrimaryButton text="Generate Message" onClick={this.generateMessage} disabled={!canGenerateMessage} />
                    </div>
                    <Link className="secondary footer-link" onClick={this.onFaqClick}>Got a question? See our FAQ</Link>
                    <div>
                        <Link className="secondary footer-link" onClick={this.showTutorial} style={{ marginRight: 5 }}>
                            Replay Tutorial
                        </Link>
                        <small>|</small>
                        <Link className="secondary footer-link" onClick={signOut} style={{ marginLeft: 5 }}>
                            Sign out
                        </Link>
                    </div>
                    {<EvieCallout data={this.getCalloutProps()} />}
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
      currentOnboardingStep: state.currentOnboardingStep,
      mailingList: state.mailingList,
      agentEmail: state.userData.agent_email
    }
}
export default connect(mapStateToProps)(RecruitmentLayout);