import * as React from 'react';
import Progress from './common/Progress';
import '../../styles/App.scss';
import { connect } from 'react-redux';
import EvieOverview from './EvieOverview';
import { Pivot, PivotItem, PivotLinkFormat, PivotLinkSize } from 'office-ui-fabric-react/lib/Pivot';
import GeneralLayout from './general/GeneralLayout';
import RecruitmentLayout from './recruitment/RecruitmentLayout';
import { updateActivePivotTab } from '../../actions';

class App extends React.Component {
  render() {
    const {
      isOfficeInitialized,
      isSignedIn,
      fetchingUserData,
      userData,
      dispatch,
      loading,
      loadingMessage
    } = this.props;

    if (!isOfficeInitialized || (!isSignedIn && fetchingUserData) || loading) {
      const title = loadingMessage || "Getting ready...";
      return (
        <Progress
          title={title}
          logo='assets/Evie-Logo-filled.png'
        />
      );
    }

    if(!isSignedIn) return <EvieOverview />;

    const isRecruitmentEnabled = userData && userData.recruitment_enabled;

    const defaultActiveTab = isRecruitmentEnabled ? 1: 0;
    const activePivotData = (activeTab, sendGA=true) => {return {activeTab, isRecruitmentEnabled, sendGA}};
    dispatch(updateActivePivotTab(activePivotData(defaultActiveTab, false)));

    const onTabChanged = (link) => dispatch(updateActivePivotTab(activePivotData(link.props.itemKey === "General" ? 0 : 1)));

    return (
      <div className="evie-app">
        <Pivot linkSize={PivotLinkSize.small} linkFormat={PivotLinkFormat.tabs} initialSelectedIndex={defaultActiveTab} onLinkClick={onTabChanged}>
          <PivotItem headerText="General" itemKey="General" key="General">
            <GeneralLayout />
          </PivotItem>
          <PivotItem headerText="Recruitment" itemKey="Recruitment" key="Recruitment">
            { isRecruitmentEnabled && <RecruitmentLayout /> }
            {!isRecruitmentEnabled && (
              <div className="recruitment-disabled-warning">
                <span>
                  <h4>Oops...</h4>
                  <h6>
                    Evie for recruitment is not enabled for you.
                    <br/><br/>
                    Reach out to <a href="mailto:hello@evie.ai">hello@evie.ai</a> to find out more.
                  </h6>
                </span>
              </div>
            )}
          </PivotItem>
        </Pivot>
      </div>
    );
  }
}


const mapStateToProps = function(state) {
  return {
    isSignedIn: state.isSignedIn,
    userData: state.userData,
    fetchingUserData: state.fetchingUserData,
    loading: state.loading,
    loadingMessage: state.loadingMessage
  }
}

export default connect(mapStateToProps)(App);