export const STEP_INTRO = 0;
export const STEP_GEN_MAILING_LIST_INTRO = 1;
export const STEP_MESSAGE_BUILDER_INTRO = 2;
export const STEP_MEETING_TYPE = 3;
export const STEP_GEN_SELECT_LOCATION = 4;
export const STEP_GEN_GENERATE_MESSAGE = 5;
export const STEP_GEN_PRE_COMPLETE = 6;
export const STEP_PINNING = 7;
export const STEP_COMPLETE = 8;

export const STEP_RECRUITMENT_INTRO = 100;
export const STEP_RECRUITMENT_MAILING_LIST_INTRO = 101;
export const STEP_RECRUITMENT_BUILDER_INTRO = 102;
export const STEP_RECRUITMENT_WHEN_EDIT = 103;
export const STEP_RECRUITMENT_ADD_CANDIDATE = 104;
export const STEP_RECRUITMENT_SELECT_LOCATION = 105;
export const STEP_RECRUITMENT_GENERATE_MESSAGE = 106;
export const STEP_RECRUITMENT_PRE_COMPLETE = 107;
export const STEP_RECRUITMENT_COMPLETE = 108;

export const GA_ONBOARDING_STEP_MAP = {
    0: "gen_start",
    1: "gen_autocomplete",
    2: "gen_msgbuilder",
    3: "gen_meetingtype",
    4: "gen_addloc",
    5: "gen_clickgenmsg",
    6: "gen_populatemsg",
    7: "gen_pin",
    8: "gen_clickdone",
    100: "rec_start",
    101: "rec_addrecipient",
    102: "rec_msgbuilder",
    103: "rec_edittimepref",
    104: "rec_addcand",
    105: "rec_addloc",
    106: "rec_clickgenmsg",
    107: "rec_populatemsg",
    108: "rec_clickdone",
}

export const INTENTS = [
    { key: 'schedule', text: 'schedule' },
    { key: 'send_invite', text: 'send an invite for' },
    { key: 'block_calendar', text: 'block my calendar' }
]

export const MEETING_TYPES = [
    { key: 'meeting', text: 'meeting' },
    { key: 'call', text: 'call' },
    { key: 'mobile_call', text: 'mobile call' },
    { key: 'skype_call', text: 'skype call' },
    { key: 'web_conference_call', text: 'web conference call' },
    { key: 'zoom_call', text: 'zoom call' },
    { key: 'coffee', text: 'coffee' },
    { key: 'breakfast', text: 'breakfast' },
    { key: 'lunch', text: 'lunch' },
    { key: 'dinner', text: 'dinner' },
]