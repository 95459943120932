import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import EditableLabel from 'react-inline-editing';
import CandidateList from './CandidateList';
import { connect } from 'react-redux';
import '../../../styles/MessageBuilder.scss';
import { Link } from 'office-ui-fabric-react';
import { locationPrefix } from '../../../utils/messageBuilderUtil';
import { 
    STEP_RECRUITMENT_BUILDER_INTRO,
    STEP_RECRUITMENT_WHEN_EDIT,
    STEP_RECRUITMENT_ADD_CANDIDATE,
    STEP_RECRUITMENT_SELECT_LOCATION,
    STEP_RECRUITMENT_GENERATE_MESSAGE
} from '../../../constants';
import { updateCurrentStep } from '../../../actions';
import EvieCallout from '../common/EvieCallout';
import LocationSelector from '../common/LocationSelector';
import SignatureComponent from '../common/SignatureComponent';

class RecruitmentMessageBuilder extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            when: "next week",
            editingWhen: false,
            candidates: [],
            selectedLocations: [],
            shouldBookRoom: null,
            hmLocationData: ''
        }
        this.whenEditorRef = React.createRef();
        this.whenEditorMainRef = React.createRef();
        this.messageBuilderRef = React.createRef();
        this.candidateListRef = React.createRef();
        this.locationContainerRef = React.createRef();
    }

    editWhen = () => {
        if(!this.whenEditorRef || !this.whenEditorRef.current){
            return;
        }
        this.whenEditorRef.current.setState({isEditing: true});
        this.setState({editingWhen: true});
    }

    notifyChanges = () => {
        const { when, candidates, hmLocationData, shouldBookRoom } = this.state;
        let builderData = {
            when,
            candidates,
            selectedLocation: this.selectedLocationString(),
            shouldBookRoom: shouldBookRoom,
            hmLocationData
        }
        this.props.onMessageDataChanged(builderData);
    }

    onWhenChanged = (data) => {
        this.setState({when: data, editingWhen: false}, () => this.notifyChanges());
        ga('send', 'event', 'rec_edittimepref', data);
        if (this.props.currentOnboardingStep === STEP_RECRUITMENT_WHEN_EDIT) {
            this.props.dispatch(updateCurrentStep({step: STEP_RECRUITMENT_ADD_CANDIDATE}));
        }
    }

    onCandidateListUpdated = (updatedList) => {
        this.setState({candidates: updatedList}, () => this.notifyChanges());
        if (this.props.currentOnboardingStep === STEP_RECRUITMENT_ADD_CANDIDATE) {
            const nextStep = this.officeList().length > 1 ? STEP_RECRUITMENT_SELECT_LOCATION : STEP_RECRUITMENT_GENERATE_MESSAGE;
            setTimeout(()=>this.props.dispatch(updateCurrentStep({step: nextStep})),300);
        }
    }

    showWhenEditor = () => {
        return (
            <div className="inline-editor short" onClick={this.editWhen} ref={this.whenEditorMainRef}>
                    <EditableLabel
                        ref={this.whenEditorRef}
                        text={this.state.when}
                        labelClassName='inline-label'
                        inputClassName='inline-input'
                        inputPlaceHolder='when?'
                        onFocus={() => this.setState({editingWhen: true})}
                        onFocusOut={this.onWhenChanged}
                    />
                {!this.state.editingWhen && (
                    <Icon className="icon" iconName="Edit" aria-label="Some help" />
                )}
            </div>
    )}

    officeList = () => {
        if (!this.props.userData.team_offices) return [];
        const offices = this.props.userData.team_offices.map((office) => {return {text: office}});
        return offices;
    }

    onLocationChanged = (locations) => {
        this.setState(locations, () => this.notifyChanges());
        ga('send', 'event', 'rec_loc_change');
        if (this.props.currentOnboardingStep === STEP_RECRUITMENT_SELECT_LOCATION) {
            this.props.dispatch(updateCurrentStep({step: STEP_RECRUITMENT_GENERATE_MESSAGE}));
        }
    }

    onHmLocationChange = (data) => {
        this.setState({hmLocationData: data}, () => this.notifyChanges());
    }

    selectedLocationString = () => {
        const locations = this.state.selectedLocations.map(l => l.text);
        if (locations.length === 0) return "";
        if (locations.length === 1) return locations[0];
        const lastLocation = locations.pop();
        return locations.join(", ") + " and " + lastLocation;
    }

    getCalloutProps = () => {
        switch (this.props.currentOnboardingStep) {
            case STEP_RECRUITMENT_BUILDER_INTRO:
                return {
                    description: "Build your request with the message builder!",
                    target: this.messageBuilderRef,
                    action: () => this.props.dispatch(updateCurrentStep({step: STEP_RECRUITMENT_WHEN_EDIT}))
                };
            case STEP_RECRUITMENT_WHEN_EDIT:
                    return {
                        description: (
                                    <span>
                                        Let's practice by suggesting a preferred time period.
                                        <br/><br/>
                                        <small style={{color: 'grey'}}>
                                            (click <Icon className="icon" iconName="Edit" aria-label="Edit" /> to edit)
                                        </small>
                                    </span>
                        ),
                        target: this.whenEditorMainRef,
                        action: () => this.props.dispatch(updateCurrentStep({step: STEP_RECRUITMENT_ADD_CANDIDATE}))
                    };
            case STEP_RECRUITMENT_ADD_CANDIDATE:
                    return {
                        description: <span>Great!<br/><br/>Now, let's add a candidate.</span>,
                        target: this.candidateListRef
                    };
            case STEP_RECRUITMENT_SELECT_LOCATION:
                    return {
                        description: "Search and add a location where the interview will take place.",
                        target: this.locationContainerRef
                    };
        }
    }

    render () {
        const { candidates } = this.state;
        const pluralize = candidates.length > 1 ? 's' : '';

        return (
            <Col xs={12} className="message-builder">
                <Row className="label">
                    <span className="text" ref={this.messageBuilderRef}>Message Builder</span>
                    <TooltipHost content="Use the options below to build your request message. Click on ‘Generate Message’ once you’re done.">
                        <Icon className="icon" iconName="Info" aria-label="info" />
                    </TooltipHost>
                </Row>
                <div className="builder-card">
                    <span>Hi Evie,</span><br/>
                    <span>Please schedule {pluralize?' ':'an '}interview{pluralize} {this.showWhenEditor()} </span>
                    <span>with the following candidate{pluralize} :</span>
                    <div ref={this.candidateListRef}><CandidateList onCandidateListUpdated={this.onCandidateListUpdated} /></div>
                    <div ref={this.locationContainerRef}><LocationSelector onChange={this.onLocationChanged} /></div>
                    <SignatureComponent />
                </div>
                {<EvieCallout data={this.getCalloutProps()} />}
            </Col>
        );
    }
}


const mapStateToProps = function(state) {
    return {
      userData: state.userData,
      currentOnboardingStep: state.currentOnboardingStep,
    }
}

export default connect(mapStateToProps)(RecruitmentMessageBuilder);