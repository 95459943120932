import {
  UPDATE_CURRENT_STEP,
  UPDATE_TASK_DATA,
  DETECTING_TASK_STATE,
  IS_NEW_TASK,
  SIGNIN_DATA,
  FETCHING_USER_DATA,
  UPDATE_ACTIVE_PIVOT_TAB,
  SET_LOADING,
  UPDATE_MAILING_LIST,
  INCLUDE_SIGNATURE
} from "../constants/action-types";

export function updateCurrentStep(payload) {
  return { type: UPDATE_CURRENT_STEP, payload };
}

export function updateTaskData(payload) {
  return { type: UPDATE_TASK_DATA, payload };
}

export function setDetectingTaskState(payload) {
  return { type: DETECTING_TASK_STATE, payload };
}

export function setIsNewTask(payload) {
  return { type: IS_NEW_TASK, payload };
}

export function updateSigninData(payload) {
  return { type: SIGNIN_DATA, payload };
}

export function updateFetchingUserData(payload) {
  return {type: FETCHING_USER_DATA, payload};
}

export function updateActivePivotTab(payload) {
  return {type: UPDATE_ACTIVE_PIVOT_TAB, payload};
}

export function setLoading(payload) {
  return {type: SET_LOADING, payload};
}

export function updateMailingList(payload) {
  return {type: UPDATE_MAILING_LIST, payload};
}

export function setIncludeSignature(payload) {
  return {type: INCLUDE_SIGNATURE, payload};
}