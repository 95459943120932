import * as React from 'react';
import SignIn from './SignIn';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { PrimaryButton } from 'office-ui-fabric-react';
import { API_HOST_BASE } from '../../constants/env';
import { Link } from 'office-ui-fabric-react/lib/Link';

export default class EvieOverview extends React.Component {
    constructor(context, props) {
        super(context, props);
        this.state = {
            showSignin: false
        };

        this.overviewItems = [
            {icon: "Message", content: "Communicate effectively with Evie", subContent: "Use suggested phrases to help you easily build your message request"},
            {icon: "Sync", content: "Never leave Evie out of the loop again​", subContent: "Automatically cc your assistant whenever you type ‘Evie’​"},
            {icon: "FileCode", content: "Use a standard template to schedule interviews​", subContent: "You’ll always have all the details needed to schedule interviews​"}
        ]
    }

    showSigninLayout = () => {
        this.setState({ showSignin: true });
    }

    render() {
        if (this.state.showSignin) return <SignIn />

        return (
            <div className="evie-overview">
                <img className="logo" width='90' height='90' src="assets/Evie-Logo-filled.png" alt="Evie logo" title="Evie logo" />
                {this.overviewItems.map((item) => {
                    return (
                        <div className="overview-item">
                            <Icon className="icon" iconName={item.icon} aria-label={item.icon} />
                            <div className="text">
                                <p className="content">​{item.content}</p>
                                <p className="sub-content">{item.subContent}​</p>
                            </div>
                        </div>
                    );
                })}
                <PrimaryButton text="Sign in" onClick={this.showSigninLayout} />
                <p><small>Don't have an account?</small></p>
                <Link className="secondary footer-link center" target="_blank" href={`${API_HOST_BASE}/register`}>Signup for a free trial</Link>
            </div>
        );
    }
}