import $ from "jquery";
import store from '../store';
import { updateTaskData, setDetectingTaskState, setIsNewTask } from '../actions';
import { SUGGESTIONS_BY_STATE , SUGGESTIONS_NEW_TASK} from '../constants/task';

export const parseMsgBody = () => {
    Office.context.mailbox.item.body.getAsync(
        Office.CoercionType.Html,
        (result) => {
            if (result.status == Office.AsyncResultStatus.Failed){
                console.log("Action failed with error: " + result.error.message);
            } else {
                parseHtml(result.value);
            }
        }
    );
}

const parseHtml = (body) => {
    const el = $('<div></div>');
    el.html(body);
    const taskIdContainers = el.find("a");
    if (!taskIdContainers) return updateStore(true, SUGGESTIONS_NEW_TASK);
    for(let taskIdContainer of taskIdContainers) {
        taskIdContainer = $(taskIdContainer);
        const detectedTaskState = isEvieTaskId(taskIdContainer.text());
        if(detectedTaskState) {
            const taskData = SUGGESTIONS_BY_STATE[detectedTaskState];
            if(taskData) {
                return updateStore(false, taskData);
            }
        }
    }
    updateStore(true, SUGGESTIONS_NEW_TASK);
}

const updateStore = (isNewTask, taskData) => {
    store.dispatch(setIsNewTask(isNewTask));
    store.dispatch(updateTaskData(taskData));
    detectingTaskState(false);
}

const detectingTaskState = (detecting) => {
    store.dispatch(setDetectingTaskState(detecting));
}

const isEvieTaskId = (text) => {
    const match = text.match(/ID: [0-9]+-([A-Z]{1,3})/);
    return match && match[1];
}