import * as React from 'react';
import { connect } from 'react-redux';
import '../../styles/SignIn.scss';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton } from 'office-ui-fabric-react';
import Progress from './common/Progress';
import OtpInput from 'react-otp-input';
import * as API from '../../api';
import { fetchUserData } from '../../utils/userDataUtil';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { API_HOST_BASE } from '../../constants/env';

class SignIn extends React.Component {
    constructor(context, props) {
        super(context, props);
        const userEmail = Office.context.mailbox.userProfile.emailAddress;
        this.state = {
            email: userEmail,
            otp: {
                value: "",
                sent: false,
                verifying: false,
                sending: false,
                error: null,
            }
        };
    }

    onOtpSent = (data) => {
        // console.log(data);
        const otp = this.state.otp;
        if (data.success) {
            otp.sent = true;
            ga('send', 'event', 'otp_request', 'success');
        } else {
            otp.error = data.error;
            ga('send', 'event', 'otp_request', 'failed', `${data.error}`);
        }
        otp.sending = false;
        this.setState({ otp });
    }

    onOtpVerified = (data) => {
        const otp = this.state.otp;
        if (data.success) {
            ga('send', 'event', 'otp_verify', 'success');
            fetchUserData(true);
        } else {
            otp.error = data.error;
            otp.value = "";
            ga('send', 'event', 'otp_verify', 'failed', `${data.error}`);
        }
        otp.verifying = false;
        this.setState({ otp });
    }

    verifyOtp = () => {
        const otp = this.state.otp;
        this.setState({ otp: {...otp, verifying: true, error: null} });
        Office.context.mailbox.getUserIdentityTokenAsync((result) => {
            const userToken = result.value;
            API.verifyOtp(this.state.email, this.state.otp.value, userToken)
            .then(data => this.onOtpVerified(data))
            .catch(error => this.onOtpVerified({success: false, error: error.message}));
        });
        ga('send', 'event', 'otp_verify', 'click');
    }

    sendOtp = () => {
        const otp = this.state.otp;
        this.setState({ otp: {...otp, sending: true, error: null} });
        API.sendOtp(this.state.email)
        .then(data => this.onOtpSent(data))
        .catch(error => this.onOtpSent({success: false, error: error.message}));
        ga('send', 'event', 'otp_request', 'click');
    }

    performAction = () => {
        if (this.state.otp.sent) {
            this.verifyOtp();
        } else {
            this.sendOtp();
        }
    }

    onEmailChanged = (email) => {
        this.setState({ email });
    }

    onOtpChanged = (value) => {
        const otp = this.state.otp;
        otp.value = value;
        this.setState({ otp });
    }

    render() {
        const { email, otp } = this.state;
        const header =  otp.error || (
            otp.sent ? <span>We've sent an OTP to <strong>{email}</strong></span>
                     : <small>You need to be signed in to continue<br/>Click 'Next' and we'll send you a one-time password (OTP)</small>
        );
        const actionButtonLabel = otp.sent ? 'Sign in' : 'Next';

        if (otp.sending || otp.verifying) {
            const title = <small>{otp.sending ? "Sending the OTP to your email..." : "Verifying the OTP..."}</small>;
            return (
                <Progress
                    title={title}
                    logo='assets/Evie-Logo-filled.png'
                />
            );
        }

        return (
            <div className="evie-signin">
                <img width='90' height='90' src="assets/Evie-Logo-filled.png" alt="Evie Logo" />
                <h6 class={"label" + `${otp.error ? ' error' : ''}`}>{header}</h6>
                {!otp.sent && (
                    <TextField
                        label="Your Evie email address:"
                        value={email}
                        type="email"
                        disabled={email!=null}
                        style={{background: "rgb(243, 242, 241)"}}
                        onChange={(e, v) => this.onEmailChanged(v)}/>
                )}
                {otp.sent && (
                    <React.Fragment>
                        <p><strong>Please enter the OTP</strong></p>
                        <OtpInput
                            containerStyle={{marginTop: 10}}
                            inputStyle={{width: '1.5rem',  border: '1px solid grey'}}
                            errorStyle={{border: '1px solid red'}}
                            value={otp.value}
                            onChange={otp => this.onOtpChanged(otp)}
                            numInputs={6}
                            separator="-"
                            shouldAutoFocus={true}
                            hasErrored={otp.error}
                        />
                        {otp.error && <Link style={{margin: 0, marginTop: 10, fontSize: 12}} onClick={this.sendOtp}>Resend OTP</Link>}
                    </React.Fragment>
                )}
                <PrimaryButton text={actionButtonLabel} onClick={this.performAction} disabled={this.state.otp.sent && this.state.otp.value.length < 6} />
                {!otp.sent && <Link className="secondary footer-link center" target="_blank" href={`${API_HOST_BASE}/register`}>Not registered? Register here</Link>}
            </div>
        );
    }
}

const mapStateToProps = function(state) {
    return {
      isSignedIn: state.isSignedIn
    }
}
  
export default connect(mapStateToProps)(SignIn);