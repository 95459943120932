const getNextWeekFormattedDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 21);
    const month = date.toLocaleString(undefined, { month: 'short' });
    return `${date.getDate()} ${month}`;
}

export const SUGGESTIONS_NEW_TASK = [
    {
        title: "Suggested Actions",
        color: "orange",
        help: "Evie will schedule the type of meeting you want.",
        suggestions: [
            "schedule a call ",
            "plan a f2f meeting​",
            "block my calendar for --exact time/date--​"
        ]
    },
    {
        title: "Preferred time frames​",
        color: "green",
        help: "Evie will try to schedule within the specified time frame.",
        showCallout: true,
        suggestions: [
            "next week​",
            "later this week​",
            `in the week of ${getNextWeekFormattedDate()}`,
            "Tuesday 3pm​"
        ]
    },
    {
        title: "Time zones and travel​",
        color: "blue",
        help: "Evie will take the time zones of these cities into account for the time frame specified.",
        suggestions: [
            "--name-- is based in --city name--​",
            "--name-- will be in --city name-- next week​"
        ]
    },
    {
        title: "Meeting room booking​",
        color: "yellow",
        help: "Evie will book meeting room in the location you want.",
        suggestions: [
            "Book a room in --office 1-- and --office 2--​"
        ]
    }
]

export const SUGGESTIONS_BY_STATE = {
    IP: [
        {
            title: "Suggested Actions",
            color: "orange",
            help: "Evie will schedule the type of meeting you want.",
            suggestions: [
                "reschedule to",
                "cancel",
                "confirm --exact time/date--​",
                "send an invite for --exact time/date--​"
            ]
        },
        {
            title: "Preferred time frames​",
            color: "green",
            help: "Evie will try to schedule within the specified time frame.",
            showCallout: true,
            suggestions: [
                "next week​",
                "later this week​",
                `in the week of ${getNextWeekFormattedDate()}`,
                "Tuesday 3pm​"
            ]
        },
        {
            title: "Time zones and travel​",
            color: "blue",
            help: "Evie will take the time zones of these cities into account for the time frame specified.",
            suggestions: [
                "--name-- is based in --city name--​",
                "--name-- will be in --city name-- next week​"
            ]
        }
    ],
    CL: [
        {
            title: "Suggested Actions",
            color: "orange",
            help: "Evie will schedule the type of meeting you want.",
            suggestions: [
                "schedule a call ",
                "plan a f2f meeting​",
                "block my calendar for --exact time/date--​"
            ]
        },
        {
            title: "Preferred time frames​",
            color: "green",
            help: "Evie will try to schedule within the specified time frame.",
            showCallout: true,
            suggestions: [
                "next week​",
                "later this week​",
                `in the week of ${getNextWeekFormattedDate()}`,
                "Tuesday 3pm​"
            ]
        },
        {
            title: "Time zones and travel​",
            color: "blue",
            help: "Evie will take the time zones of these cities into account for the time frame specified.",
            suggestions: [
                "--name-- is based in --city name--​",
                "--name-- will be in --city name-- next week​"
            ]
        }
    ],
    CF: [
        {
            title: "Suggested Actions",
            color: "orange",
            help: "Evie will schedule the type of meeting you want.",
            suggestions: [
                "reschedule to",
                "update to --exact time/date--​",
                "cancel",
                "update the location to --new location--​"
            ]
        },
        {
            title: "Preferred time frames​",
            color: "green",
            help: "Evie will try to schedule within the specified time frame.",
            showCallout: true,
            suggestions: [
                "next week​",
                "later this week​",
                `in the week of ${getNextWeekFormattedDate()}`,
                "Tuesday 3pm​"
            ]
        },
        {
            title: "Time zones and travel​",
            color: "blue",
            help: "Evie will take the time zones of these cities into account for the time frame specified.",
            suggestions: [
                "--name-- is based in --city name--​",
                "--name-- will be in --city name-- next week​"
            ]
        }
    ]
}