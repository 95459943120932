import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import EditableLabel from 'react-inline-editing';
import { connect } from 'react-redux';
import '../../../styles/MessageBuilder.scss';
import {
    STEP_MESSAGE_BUILDER_INTRO,
    STEP_MEETING_TYPE,
    STEP_GEN_SELECT_LOCATION,
    STEP_GEN_GENERATE_MESSAGE,
    INTENTS,
    MEETING_TYPES
} from '../../../constants';
import { updateCurrentStep, setIncludeSignature } from '../../../actions';
import EvieCallout from '../common/EvieCallout';
import { Dropdown, ResponsiveMode } from 'office-ui-fabric-react/lib/Dropdown';
import DateTimePicker from 'react-datetime-picker';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import LocationSelector from '../common/LocationSelector';
import SignatureComponent from '../common/SignatureComponent';
import InviteeLocation from '../common/InviteeLocation';
import dayjs from 'dayjs'
import { Link } from 'office-ui-fabric-react';

class GeneralMessageBuilder extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            when: "next week",
            duration: "",
            whenDateTime: new Date(),
            editingWhen: false,
            selectedLocations: [],
            shouldBookRoom: null,
            inviteeLocationData: '',
            intent: INTENTS[0],
            durationEnabled: false,
            meetingType: MEETING_TYPES[0],
            callLocation: ''
        }
        this.whenEditorRef = React.createRef();
        this.meetingTypeRef = React.createRef();
        this.messageBuilderRef = React.createRef();
        this.locationContainerRef = React.createRef();
        this.notifyChanges();
    }

    editWhen = () => {
        if(!this.whenEditorRef || !this.whenEditorRef.current){
            return;
        }
        this.whenEditorRef.current.setState({isEditing: true});
        this.setState({editingWhen: true});
    }

    notifyChanges = () => {
        const { when, whenDateTime, inviteeLocationData, duration, intent, meetingType, callLocation } = this.state;
        const whenData = this.isSchedule() ? when : dayjs(whenDateTime).format('DD MMMM h:mm a');;
        let builderData = {
            when: whenData,
            duration,
            selectedLocation: this.selectedLocationString(),
            shouldBookRoom: this.isCall() || this.state.shouldBookRoom,
            inviteeLocationData: this.isSchedule() && this.isCall() ? inviteeLocationData : null,
            intent,
            meetingType: this.isBlockCalendar() ? null : meetingType,
            callLocation: !this.isBlockCalendar() && this.isCall() ? callLocation : null
        }
        this.props.onMessageDataChanged(builderData);
    }

    onWhenChanged = (data) => {
        this.setState({when: data, editingWhen: false}, () => this.notifyChanges());
    }

    isCall() {
        const meetingType = this.state.meetingType.key.split("_");
        return meetingType[meetingType.length - 1] === "call";
    }

    isSchedule() {
        return this.state.intent.key === "schedule";
    }

    isBlockCalendar() {
        return this.state.intent.key === "block_calendar";
    }

    isSendInvite() {
        return this.state.intent.key === "send_invite";
    }

    showWhenEditor = () => {
        return (
            <div className={`inline-editor short mb-1`}
                onClick={this.editWhen} style={{margin: 0}}>
                <EditableLabel
                    ref={this.whenEditorRef}
                    text={this.state.when}
                    labelClassName='inline-label'
                    inputClassName='inline-input'
                    inputPlaceHolder='when?'
                    onFocus={() => this.setState({editingWhen: true})}
                    onFocusOut={this.onWhenChanged}
                />
                {!this.state.editingWhen && (
                    <Icon className="icon" iconName="Edit" aria-label="Some help" />
                )}
            </div>
    )}

    showDateTimePicker() {
        return (
            <DateTimePicker
                calendarClassName="date-time-calendar"
                clockClassName="date-time-clock"
                format={"dd-MM-y h:mm a"}
                value={this.state.whenDateTime}
                disableClock={true}
                onChange={value => this.setState({ whenDateTime: value }, () => this.notifyChanges())}
            />
        );
    }

    onInviteeLocationChange = (data) => {
        this.setState({inviteeLocationData: data}, () => this.notifyChanges());
    }

    selectedLocationString = () => {
        if (this.isBlockCalendar()) return '';
        const locations = this.state.selectedLocations.map(l => l.text);
        if (locations.length === 0) return "";
        if (locations.length === 1) return locations[0];
        const lastLocation = locations.pop();
        return locations.join(", ") + " and " + lastLocation;
    }

    onLocationChange = (changes) => {
        this.setState(changes, () => this.notifyChanges());
        ga('send', 'event', 'gen_loc_change');
        if (this.props.currentOnboardingStep === STEP_GEN_SELECT_LOCATION) {
            this.props.dispatch(updateCurrentStep({step: STEP_GEN_GENERATE_MESSAGE}));
        }
    }

    createDropdown = (options, prop, width=150) => {
        return (
            <Dropdown
                className="mb-1"
                selectedKey={this.state[prop].key}
                onChange={(e, newProp) => {
                    const state = this.state;
                    state[prop] = newProp;
                    this.setState(state, () => this.notifyChanges());
                    ga('send', 'event', `gen_${prop}_changed`, newProp);
                    if (this.props.currentOnboardingStep === STEP_MEETING_TYPE && prop==="meetingType") {
                        this.props.dispatch(updateCurrentStep({step: STEP_GEN_SELECT_LOCATION}));
                    }
                }}
                placeholder="Select an option"
                responsiveMode={ResponsiveMode.Large}
                options={options}
                styles={{ dropdown: { width: width } }}
            />
        );
    }

    toggleDuration = () => {
        this.setState({durationEnabled: !this.state.durationEnabled, duration: ''}, () => this.notifyChanges());
        ga('send', 'event', `gen_duration_toggled`, `${!this.state.durationEnabled}`);
    }

    showCallLocationPicker = () => {
        return (
            <div className="inline-editor-wrapper">
                <span>Location is</span>
                <TooltipHost content="You can use this to override your default call location.">
                    <Icon className="icon" iconName="Info" aria-label="info" />
                </TooltipHost>
                <div className="inline-editor">
                    <textarea
                        rows={Math.max(this.state.callLocation.split("\n").length, 1)}
                        placeholder="e.g. https://zoom.us/abc"
                        value={this.state.callLocation}
                        onChange={(e) => this.setState({callLocation: e.target.value}, () => this.notifyChanges())}/>
                    <Icon className="icon" iconName="Edit" aria-label="Delete" />
                </div>
            </div>
        );
    }

    getCalloutProps = () => {
        switch (this.props.currentOnboardingStep) {
            case STEP_MESSAGE_BUILDER_INTRO:
                return {
                    description: "Build your request with the message builder!",
                    target: this.messageBuilderRef,
                    action: () => this.props.dispatch(updateCurrentStep({step: STEP_MEETING_TYPE}))
                };
            case STEP_MEETING_TYPE:
                    return {
                        description: "Let's practice by selecting the type of meeting.",
                        target: this.meetingTypeRef,
                        action: () => this.props.dispatch(updateCurrentStep({step: STEP_GEN_SELECT_LOCATION}))
                    };
            case STEP_GEN_SELECT_LOCATION:
                    return {
                        description: "Search and add a location where the meeting will take place.",
                        target: this.locationContainerRef,
                        action: () => this.props.dispatch(updateCurrentStep({step: STEP_GEN_GENERATE_MESSAGE}))
                    };
        }
    }

    durationPrefix = () => {
        if(this.isBlockCalendar() && this.state.durationEnabled) return <p>for</p>;
        if(!this.isBlockCalendar()) return <p>a</p>;
    }

    whenPrefix = () => {
        if(this.isBlockCalendar()) return <p>at</p>;
        if(this.isSendInvite()) return <p>on</p>;
    }

    render () {
        const intentSelector = () => this.createDropdown(INTENTS, "intent");
        const meetingTypeSelector = () => this.createDropdown(MEETING_TYPES, "meetingType", 90);

        return (
            <Col xs={12} className="message-builder general">
                <Row className="label">
                    <span className="text" ref={this.messageBuilderRef}>Message Builder</span>
                    <TooltipHost content="Use the options below to build your request message. Click on ‘Generate Message’ once you’re done.">
                        <Icon className="icon" iconName="Info" aria-label="info" />
                    </TooltipHost>
                </Row>
                <div className="builder-card">
                    <span>Hi Evie,</span><br/>
                    <span className="flex">
                        <p>Please</p> {intentSelector()}
                        {this.durationPrefix()}
                        {this.state.durationEnabled && (
                            <TextField
                                className="mb-1"
                                value={this.state.duration} onChange={(e, v) => this.setState({duration: v}, () => this.notifyChanges())}
                                placeholder="e.g. 90" suffix="min"
                                />
                        )}
                        {!this.isBlockCalendar() && (
                            <React.Fragment>
                                <div ref={this.meetingTypeRef}>{meetingTypeSelector()}</div>
                                {this.isSchedule() && this.showWhenEditor()}
                            </React.Fragment>
                        )}
                        {!this.isSchedule() && (
                            <React.Fragment>
                                {this.whenPrefix()}
                                {this.showDateTimePicker()}
                            </React.Fragment>
                        )}
                    </span>
                    <Link className="secondary link" onClick={() => this.toggleDuration()}>
                        {this.state.durationEnabled ? 'Hide duration' : 'Specify a duration'}
                    </Link>
                    {!this.isBlockCalendar() && (
                        <React.Fragment>
                            {this.isCall() && this.showCallLocationPicker()}
                            <div ref={this.locationContainerRef}>
                                <LocationSelector
                                    onChange={this.onLocationChange} shouldBookRoom={this.isCall()} hideControl={this.isCall()} />
                            </div>
                            {this.isSchedule() && this.isCall() && (
                                <InviteeLocation onChange={this.onInviteeLocationChange} />
                            )}
                        </React.Fragment>
                    )}
                    <SignatureComponent />
                </div>
                {<EvieCallout data={this.getCalloutProps()} />}
            </Col>
        );
    }
}


const mapStateToProps = function(state) {
    return {
      userData: state.userData,
      currentOnboardingStep: state.currentOnboardingStep,
    }
}

export default connect(mapStateToProps)(GeneralMessageBuilder);