export const KEY_AUTO_COMPLETE_TEMPLATE = "auto_complete_template";
export const KEY_AUTO_COMPLETE_ENABLED = "auto_complete_enabled";
export const KEY_ONBOARDING_STEP = "onboarding_step";
export const KEY_IS_SIGNED_IN = "is_signed_in";
export const KEY_USER_DATA = "user_data";
export const KEY_INCLUDE_SIGNATURE = "include_signature";

export const AUTO_COMPLETE_TEMPLATE_DEFAULT = "please schedule a meeting for us";
export const AUTO_COMPLETE_ENABLED_DEFAULT = true;
export const ONBOARDING_STEP_DEFAULT = null;
