import * as RoamingSettings from '../constants/roamingSettings';
import { STEP_INTRO, STEP_RECRUITMENT_INTRO } from '../constants';
import * as Actions from '../actions';
import store from '../store';

/* Getters */

export const currentOnboardingStep = () => {
    let currentStep = Office.context.roamingSettings.get(RoamingSettings.KEY_ONBOARDING_STEP);
    if (currentStep == null) { 
        const introStep = userData().activePivotTab == 0 ? STEP_INTRO : STEP_RECRUITMENT_INTRO;
        currentStep = introStep;
    }
    return currentStep;
}

export const isSignedIn = () => {
    const isSignedIn = Office.context.roamingSettings.get(RoamingSettings.KEY_IS_SIGNED_IN);
    return isSignedIn === true;
}

export const userData = () => {
    const userData = Office.context.roamingSettings.get(RoamingSettings.KEY_USER_DATA);
    return userData || null;
}

export const includeSignature = () => {
    const signatureEnabled = Office.context.roamingSettings.get(RoamingSettings.KEY_INCLUDE_SIGNATURE);
    return signatureEnabled || true;
}

/* Setters */

export const updateStoreData = () => {
    if (isSignedIn()) {
        store.dispatch(Actions.updateCurrentStep({step: currentOnboardingStep(), sendGA: false}));
    }
    // store.dispatch(Actions.updateCurrentStep({step: STEP_INTRO}));
    store.dispatch(Actions.updateSigninData({isSignedIn: isSignedIn(), userData: userData()}));
    store.dispatch(Actions.setIncludeSignature(includeSignature()));
    // store.dispatch(Actions.updateSigninData({isSignedIn: false, userData: null}));
}

export const updateOnBoardingStep = (step) => {
    updateRomaingSettings(RoamingSettings.KEY_ONBOARDING_STEP, step);
}

export const updateIncludeSignature = (include) => {
    updateRomaingSettings(RoamingSettings.KEY_INCLUDE_SIGNATURE, include);
}

export const updateSigninData = (signedIn, data=null) => {
    updateRomaingSettings(RoamingSettings.KEY_IS_SIGNED_IN, signedIn);
    updateRomaingSettings(RoamingSettings.KEY_USER_DATA, data);
}

export const updateRomaingSettings = (key, value) => {
    Office.context.roamingSettings.set(key, value);
    Office.context.roamingSettings.saveAsync();
}