import * as React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

export default class InviteeLocation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inviteeLocationData: ''
        }
    }

    onInviteeLocationChange = (data) => {
        this.setState({inviteeLocationData: data});
        this.props.onChange(data)
    }

    render() {

        return (
            <div className="invitee-locations">
                <small>If any invitee is based in a different city.</small>
                <div className="inline-editor">
                    <textarea
                        rows={Math.max(this.state.inviteeLocationData.split("\n").length, 1)}
                        placeholder="e.g. John is based in London"
                        value={this.state.inviteeLocationData}
                        onChange={(e) => this.onInviteeLocationChange(e.target.value)}/>
                    <Icon className="icon" iconName="Edit" aria-label="Delete" />
                </div>
            </div>
        );
    }
}