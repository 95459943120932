import * as React from "react";
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { connect } from 'react-redux';

class MailingListHelp extends React.Component {
    getDisplayNameFromMailingList = (e) => (e.displayName || e.emailAddress).split("@")[0];

    render () {
        const { mailingList, agentEmail } = this.props;
        const toList = mailingList.to && mailingList.to.filter((e) => e.emailAddress !== agentEmail).map(this.getDisplayNameFromMailingList).join(", ");
        const ccList = mailingList.cc && mailingList.cc.filter((e) => e.emailAddress !== agentEmail).map(this.getDisplayNameFromMailingList).join(", ");

        return (
            <div className="mailing-list-help">
                {/* <span>Ensure that:</span><br/> */}
                <ul>
                    <li>
                        Required {this.props.inviteeType} <small>(Add to the To: field)</small>
                        <br/>
                        {toList &&
                            <div className="capsule-button short">
                                <Icon className="icon mr-1" style={{color: "green"}} iconName="SkypeCircleCheck" aria-label="check" />
                                {toList}
                            </div>
                        }
                        {!toList && 
                            <div className="capsule-button short red">
                                <Icon className="icon mr-1" iconName="WarningSolid" aria-label="Warning" />
                                Add at least one contact
                            </div>
                        }
                    </li>
                    <li>
                        Optional {this.props.inviteeType} <small>(Add to the Cc: field)</small>
                        <br/>
                        <div className="capsule-button short">
                            {/* <Icon className="icon mr-1" style={{color: "green"}} iconName="SkypeCircleCheck" aria-label="check" /> */}
                            {ccList ? ccList : 'None'}
                        </div>
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      mailingList: state.mailingList,
      agentEmail: state.userData.agent_email
    }
}
export default connect(mapStateToProps)(MailingListHelp);