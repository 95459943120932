import 'office-ui-fabric-react/dist/css/fabric.min.css';
import App from './components/App';
import { AppContainer } from 'react-hot-loader';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as OfficeUtil from '../utils/officeUtil';
import { Provider } from 'react-redux';
import store from '../store';
import * as RoamingSettingsUtil from '../utils/roamingSettingsUtil';
import { parseMsgBody } from '../utils/taskUtil';
import { fetchUserData } from '../utils/userDataUtil';

initializeIcons();

let isOfficeInitialized = false;

const render = (Component) => {
    ReactDOM.render(
        <AppContainer>
            <Provider store={store}>
                <Component isOfficeInitialized={isOfficeInitialized} />
            </Provider>
        </AppContainer>,
        document.getElementById('container')
    );
};

/* Render application after Office initializes */
Office.initialize = () => {
    isOfficeInitialized = true;
    RoamingSettingsUtil.updateStoreData();
    render(App);
    OfficeUtil.listenToBody();
    // parseMsgBody();
    fetchUserData();
};

/* Initial render showing a progress bar */
render(App);

if (module.hot) {
    module.hot.accept('./components/App', () => {
        const NextApp = require('./components/App').default;
        render(NextApp);
    });
}