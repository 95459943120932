import * as React from 'react';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import { Callout, DirectionalHint } from 'office-ui-fabric-react/lib/Callout';
import { Link } from 'office-ui-fabric-react/lib/Link';

export default class EvieCallout extends React.Component {
    constructor(props, context) {
        super(props, context);
        this._descriptionId = getId('callout-description');
    }

    render () {
        if(!this.props.data) return <React.Fragment></React.Fragment>;
        const { description, target, action, direction, actionText } = this.props.data;

        return (
            <Callout
                ariaDescribedBy={this._descriptionId}
                role="dialog"
                directionalHint={direction || DirectionalHint.topLeftEdge}
                target={target}
                preventDismissOnLostFocus={true}>
                <div className="callout">
                    <h6 className="description" id={this._descriptionId}>
                        {description}
                    </h6>
                    {action && (
                        <Link onClick={action}>
                            { actionText || "Next" }
                        </Link>
                    )}
                </div>
            </Callout>
        )
    }
}