import * as RoamingSettings from '../constants/roamingSettings';
import * as RoamingSettingsUtil from './roamingSettingsUtil';
import * as Actions from '../actions';
import store from '../store';
// import { STEP_INTRO_COMPLETE } from '../constants';

let evieMentioned = false;
let templateInserted = false;

const onGetBodyAsync = (result) => {
    if(!store.getState().isSignedIn) return;
    if (result.status == Office.AsyncResultStatus.Failed){
        console.log("Action failed with error: " + result.error.message);
    } else {
        const body = result.value;
        evieMentioned = isEvieMentioned(body);
        if (evieMentioned) {
            ccEvie();
            // runAutoComplete();
            updateOnboardingProgress();
        } else {
            templateInserted = false;
        }
    }
}

const updateOnboardingProgress = () => {
    // if(RoamingSettingsUtil.currentOnboardingStep() == 0) {
    //     store.dispatch(Actions.updateCurrentStep({step: STEP_INTRO_COMPLETE}));
    // }
}

const isEvieMentioned = (body) => {
    return new RegExp(/\bevie(?=[^.]*)\b/i).test(body);
}

const ccEvie = () => {
    const evieEmail = store.getState().userData.agent_email;
    if (!evieEmail) return;
    const onGetToAsync = (result) => {
        if (result.status == Office.AsyncResultStatus.Failed){
            console.log("Action failed with error: " + result.error.message);
        } else {
            const toList = result.value ? result.value.map((e) => e.emailAddress) : [];
            if (!toList.includes(evieEmail)) {
                addEmailToCC("Evie", evieEmail);
            }
        }
    }
    const onGetCcAsync = (result) => {
        if (result.status == Office.AsyncResultStatus.Failed){
            console.log("Action failed with error: " + result.error.message);
        } else {
            const ccList = result.value ? result.value.map((e) => e.emailAddress) : [];
            if (!ccList.includes(evieEmail)) {
                Office.context.mailbox.item.to.getAsync(onGetToAsync);
            }
        }
    }
    Office.context.mailbox.item.cc.getAsync(onGetCcAsync);
}

const addEmailToCC = (name, email) => {
    Office.context.mailbox.item.cc.addAsync(
        [{
            "displayName": name, 
            "emailAddress": email
        }], (result) => {
            if (result.status == Office.AsyncResultStatus.Failed){
                console.log("Action failed with error: " + result.error.message);
            }
            else {
                
            }
        }
    );
}

export const getSavedAutoCompleteTemplate = () => {
    return Office.context.roamingSettings.get(RoamingSettings.KEY_AUTO_COMPLETE_TEMPLATE)
        || RoamingSettings.AUTO_COMPLETE_TEMPLATE_DEFAULT;
}

export const isAutoCompleteEnabled = () => {
    const autoCompleteEnabled = Office.context.roamingSettings.get(RoamingSettings.KEY_AUTO_COMPLETE_ENABLED);
    return autoCompleteEnabled!=null ? autoCompleteEnabled : RoamingSettings.AUTO_COMPLETE_ENABLED_DEFAULT;
}

const runAutoComplete = () => {
    const isNewTask = store.getState().isNewTask;
    if(!isAutoCompleteEnabled() || templateInserted || !isNewTask || store.getState().activePivotTab !== 0) return;
    templateInserted = true;
    Office.context.mailbox.item.body.getAsync("text", (result) => {
        if (result.status == Office.AsyncResultStatus.Failed){
            console.log("Action failed with error: " + result.error.message);
        } else {
            const body = result.value;
            const autoCompleteTemplate = getSavedAutoCompleteTemplate();
            if (!body.includes(autoCompleteTemplate)) {
                insertSuggestion(autoCompleteTemplate);
            }
        }
    });
}

export const insertSuggestion = (text, coercionType=Office.CoercionType.Text) => {
    Office.context.mailbox.item.body.setSelectedDataAsync(
        ` ${text}`,
        { coercionType },
        (result) => {
            if (result.status == Office.AsyncResultStatus.Failed){
                console.log("Action failed with error: " + result.error.message);
            }
        });
}

export const updateMailingList = () => {
    const onGetMailingListAsync = (result, type) => {
        if (result.status == Office.AsyncResultStatus.Failed){
            console.log("Action failed with error: " + result.error.message);
        } else {
            const mailingList = result.value ? result.value : [];
            store.dispatch(Actions.updateMailingList({type: type, value: mailingList}));
        }
    }
    Office.context.mailbox.item.cc.getAsync((result) => onGetMailingListAsync(result, 'cc'));
    Office.context.mailbox.item.to.getAsync((result) => onGetMailingListAsync(result, 'to'));
}

export const listenToBody = () => {
    setInterval(() => {
        Office.context.mailbox.item.body.getAsync("text", onGetBodyAsync);
        updateMailingList();
    }, 1000)
}