import * as React from 'react';
import { setIncludeSignature } from '../../../actions';
import { connect } from 'react-redux';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';

class SignatureComponent extends React.Component {

    onSignatureToggled = () => {
        ga('send', 'event', `signature_${!this.props.includeSignature?'enabled':'disabled'}`);
        this.props.dispatch(setIncludeSignature(!this.props.includeSignature));
    }

    render() {

        return (
            <div className="signature-main">
                <div className="signature">
                    <p>Regards,</p>
                    <p>{Office.context.mailbox.userProfile.displayName}</p>
                </div>
                <Toggle
                    label={<small>Include Signature</small>}
                    inlineLabel
                    checked={this.props.includeSignature}
                    onText=" "
                    offText=" "
                    onChange={this.onSignatureToggled}
                />
            </div>
        );
    }
}

const mapStateToProps = function(state) {
    return {
      includeSignature: state.includeSignature
    }
}

export default connect(mapStateToProps)(SignatureComponent);