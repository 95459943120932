import * as React from 'react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Callout, DirectionalHint } from 'office-ui-fabric-react/lib/Callout';
import { Link } from 'office-ui-fabric-react/lib/Link';
import FormControl from 'react-bootstrap/FormControl';
import RIETextArea from 'riek/lib/RIETextArea';
import '../../../styles/CandidateList';
import { connect } from 'react-redux';
import { STEP_RECRUITMENT_ADD_CANDIDATE } from '../../../constants';

class CandidateList extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showForm: false,
            candidates: [],
            addCandidateData: {}
        }
        this.button = React.createRef();
    }

    showAddCandidateForm = () => {
        this.setState({showForm: true});
    }

    dismissForm = (save) => {
        if (save) {
            const { firstName, lastName, email, city } = this.state.addCandidateData;
            let candidateData = `${firstName} ${lastName || ""} (${email})`;
            candidateData = city ? `${candidateData}. ${firstName} is in ${city}.` : candidateData;
            const candidates = this.state.candidates;
            candidates.push(candidateData);
            this.setState({ candidates });
            this.props.onCandidateListUpdated(candidates);
            ga('send', 'event', 'rec_addcand');
        }
        this.setState({showForm: false, addCandidateData: {}});
    }

    deleteCandidate = (i) => {
        const candidates = this.state.candidates;
        candidates.splice(i, 1);
        this.setState({ candidates });
        this.props.onCandidateListUpdated(candidates);
        ga('send', 'event', 'rec_delcand');
    }

    onCandidateEdited = (data, i) => {
        const candidates = this.state.candidates;
        candidates[i] = data.candidateData;
        this.setState({ candidates });
        this.props.onCandidateListUpdated(candidates);
    }

    onFormChange = (field, e) => {
        const addCandidateData = this.state.addCandidateData;
        addCandidateData[field] = e.target.value;
        this.setState({ addCandidateData });
    }

    addCandidateForm = () => {
        const { firstName, lastName, email, city } = this.state.addCandidateData;
        const canSave = firstName && email;
        const direction = (this.props.currentOnboardingStep === STEP_RECRUITMENT_ADD_CANDIDATE) 
                            ? DirectionalHint.bottomAutoEdge : DirectionalHint.topAutoEdge;
        return (
            <Callout
                directionalHint={direction}
                role="dialog"
                target={this.button}
                isBeakVisible={false}
                gapSpace={5}
                style={{borderRadius: 6}}
                preventDismissOnLostFocus={true}>
                <div className="add-candidate-form callout">
                    <FormControl placeholder="*First name" aria-label="first name" value={firstName}
                                onChange={(e) => this.onFormChange('firstName', e)} />
                    <FormControl placeholder="Last name" aria-label="last name" value={lastName}
                                onChange={(e) => this.onFormChange('lastName', e)}/>
                    <FormControl placeholder="*Email address" aria-label="email address" value={email}
                                onChange={(e) => this.onFormChange('email', e)} />
                    {/* <FormControl placeholder="City (only if applicable)" aria-label="city" value={city}
                                onChange={(e) => this.onFormChange('city', e)} /> */}
                    <div>
                        <Link className="secondary" onClick={() => this.dismissForm(false)}>
                            Cancel
                        </Link>
                        <Link onClick={() => this.dismissForm(true)} disabled={!canSave}>
                            Save
                        </Link>
                    </div>
                </div>
            </Callout>
        );
    }

    showCandidateList = () => {
        return (
            this.state.candidates.map((candidateData, i) => {
                return (
                    <div className="inline-editor">
                            <RIETextArea
                                rows={2}
                                value={candidateData}
                                change={(data) => this.onCandidateEdited(data, i)}
                                propName='candidateData'/>
                            <Icon className="icon" iconName="Delete" onClick={this.deleteCandidate} aria-label="Delete" />
                    </div>
                );
            })
        );
    }

    render() {
        return (
            <div className="candidate-list">
                {this.showCandidateList()}
                <div className="add-candidate-button">
                    <div
                        className="capsule-button green"
                        ref={this.button}
                        onClick={() => this.showAddCandidateForm()}>
                        <span>Add Candidate</span>
                        <Icon className="icon" iconName="CirclePlus" aria-label="Add Candidate" />
                    </div>
                    {this.state.showForm && this.addCandidateForm()}
                </div>
            </div>
        );
    }
}


const mapStateToProps = function(state) {
    return {
      currentOnboardingStep: state.currentOnboardingStep
    }
}

export default connect(mapStateToProps)(CandidateList);