import * as React from 'react';
import Select from "react-dropdown-select";
import { Link } from 'office-ui-fabric-react';
import { locationPrefix } from '../../../utils/messageBuilderUtil';
import { connect } from 'react-redux';

class LocationSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedLocations: [],
            shouldBookRoom: null
        }
    }

    meetingRoomBookingPolicy = () => this.props.userData.meeting_room_booking_policy;

    shouldBookRoom = () => {
        if(this.props.shouldBookRoom) return true;
        let shouldBookRoom = this.state.shouldBookRoom;
        if (shouldBookRoom == null) shouldBookRoom = (this.meetingRoomBookingPolicy() === "dont_book" && this.props.userData.has_meeting_rooms);
        return shouldBookRoom;
    }

    onLocationChanged = (locations) => {
        this.setState({selectedLocations: locations}, () => this.notifyChanges());
    }

    toggleShouldBookRoom = () => {
        this.setState({ shouldBookRoom: !this.shouldBookRoom(), selectedLocations: [] }, () => this.notifyChanges());
    }

    officeList = () => {
        if (!this.props.userData.team_offices) return [];
        const offices = this.props.userData.team_offices.map((office) => {return {text: office}});
        return offices;
    }

    notifyChanges = () => {
        this.props.onChange(this.state);
    }

    render() {

        return (
            <div className="location-container">
                <span>{locationPrefix(this.shouldBookRoom())}</span>
                <Select
                    create={!this.shouldBookRoom()}
                    clearable={true}
                    multi={this.shouldBookRoom()}
                    options={this.officeList()}
                    values={this.state.selectedLocations}
                    labelField="text"
                    valueField="text"
                    noDataRenderer={({props, state, methods}) => <small style={{textAlign: 'center'}}>Can't find any office matching: <strong><i>{state.search}</i></strong></small>}
                    addPlaceholder={this.shouldBookRoom()? "Add more locations" : "Change location..."}
                    placeholder="Select an Office"
                    onChange={(values) => this.onLocationChanged(values)} />
                {!this.props.hideControl && (
                    <Link className="secondary link" onClick={() => this.toggleShouldBookRoom()}>
                        { this.shouldBookRoom() ?  "Don't need a room?" : 'I need a room!'}
                    </Link>
                )}
            </div>
        );
    }
}

const mapStateToProps = function(state) {
    return {
      userData: state.userData,
    }
}

export default connect(mapStateToProps)(LocationSelector);