import * as React from 'react';
import { Spinner, SpinnerType } from 'office-ui-fabric-react/lib/Spinner';

export default class Progress extends React.Component {
    render() {
        const {
            logo,
            message,
            title,
        } = this.props;

        return (
            <div className="evie-progress">
                <section className='ms-welcome__progress ms-u-fadeIn500'>
                    {logo && <img width='90' height='90' src={logo} alt="Evie logo" title="Evie logo" />}
                    <h4 className="title">{title}</h4>
                    <Spinner type={SpinnerType.large} label={message} />
                </section>
            </div>
        );
    }
}
