import {
  UPDATE_CURRENT_STEP,
  UPDATE_TASK_DATA,
  DETECTING_TASK_STATE,
  IS_NEW_TASK,
  SIGNIN_DATA,
  FETCHING_USER_DATA,
  UPDATE_ACTIVE_PIVOT_TAB,
  SET_LOADING,
  UPDATE_MAILING_LIST,
  INCLUDE_SIGNATURE
} from "../constants/action-types";
import * as RoamingSettingsUtil from '../utils/roamingSettingsUtil';
import { GA_ONBOARDING_STEP_MAP } from '../constants';

const initialState = {
  currentOnboardingStep: null,
  evieMentioned: false,
  detectingTaskState: false,
  isNewTask: false,
  isSignedIn: false,
  userData: {},
  fetchingUserData: false,
  activePivotTab: 0,
  loading: false,
  loadingMessage: '',
  mailingList: {},
  includeSignature: true
};

function rootReducer(state = initialState, action) {
  if (action.type === UPDATE_CURRENT_STEP) {
    let { step, sendGA } = action.payload;
    if (sendGA == null) sendGA = true;
    if (sendGA) ga('send', 'event', 'tutorial', 'view', `step_${GA_ONBOARDING_STEP_MAP[step]}`);
    RoamingSettingsUtil.updateOnBoardingStep(step);
    return Object.assign({}, state, {currentOnboardingStep: step});
  } else if (action.type === UPDATE_TASK_DATA) {
    return Object.assign({}, state, {taskData: action.payload});
  } else if (action.type === DETECTING_TASK_STATE) {
    return Object.assign({}, state, {detectingTaskState: action.payload});
  } else if (action.type === IS_NEW_TASK) {
    return Object.assign({}, state, {isNewTask: action.payload});
  } else if (action.type === SIGNIN_DATA) {
    const payload = action.payload;
    RoamingSettingsUtil.updateSigninData(payload.isSignedIn, payload.userData);
    return Object.assign({}, state, {isSignedIn: payload.isSignedIn, userData: payload.userData});
  } else if (action.type === FETCHING_USER_DATA) {
    return Object.assign({}, state, {fetchingUserData: action.payload});
  } else if (action.type === UPDATE_ACTIVE_PIVOT_TAB) {
    const { activeTab, isRecruitmentEnabled, sendGA } = action.payload;
    const tabChangeAction = activeTab === 0 ? "change_to_general" : "change_to_recruitment";
    if(sendGA) ga('send', 'event', 'tab', tabChangeAction, isRecruitmentEnabled ? 'recruitment_enabled': 'recruitment_disabled');
    return Object.assign({}, state, {activePivotTab: activeTab});
  } else if (action.type === SET_LOADING) {
    return Object.assign({}, state, {loading: action.payload.loading, loadingMessage: action.payload.message});
  } else if (action.type === UPDATE_MAILING_LIST) {
    const currentMailingList = JSON.parse(JSON.stringify(state.mailingList));
    currentMailingList[action.payload.type] = action.payload.value;
    return Object.assign({}, state, {mailingList: currentMailingList});
  } else if (action.type === INCLUDE_SIGNATURE) {
    RoamingSettingsUtil.updateIncludeSignature(action.payload);
    return Object.assign({}, state, {includeSignature: action.payload});
  }
  return state;
}

export default rootReducer;