import * as React from 'react';
import { 
    STEP_INTRO, STEP_GEN_MAILING_LIST_INTRO,
    STEP_MESSAGE_BUILDER_INTRO, STEP_PINNING,
    STEP_GEN_GENERATE_MESSAGE, STEP_COMPLETE,
    STEP_GEN_PRE_COMPLETE
} from '../../../constants';
import { PrimaryButton } from 'office-ui-fabric-react';
import GeneralMessageBuilder from './GeneralMessageBuilder';
import EvieLayover from '../common/EvieLayover';
import EvieIntroComplete from '../EvieIntroComplete';
import { updateCurrentStep } from '../../../actions';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { signOut } from '../../../utils/userDataUtil';
import { connect } from 'react-redux';
import EvieCallout from '../common/EvieCallout';
import MailingListHelp from '../common/MailingListHelp';
import '../../../styles/CapsuleButton.scss';
import { insertSuggestion } from '../../../utils/officeUtil';
import { buildGeneralMessageTemplate } from '../../../utils/messageBuilderUtil';

class GeneralLayout extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          typedInitiated: false,
          messageBuilderData: null
        }
        this.pinningTarget = React.createRef();
        this.generateMessageButtonRef = React.createRef();
        this.mailingListRef = React.createRef();
    }
    
    showTutorial = () => {
        ga('send', 'event', 'general_tutorial', 'replayed');
        this.props.dispatch(updateCurrentStep({step: STEP_INTRO}));
    }

    introLayoutProps = () => {
        return {
            header: "Hi, I'm Evie! <span class='wave'>👋</span>",
            subHeader: "Let me show you how this add-in can help you work with me to schedule meetings effortlessly.",
            // welcomeMessage: <span><small>Type</small> "Evie" <small>in the email body to start the tour.</small></span>,
            actionPrimary: () => this.props.dispatch(updateCurrentStep({step: STEP_GEN_MAILING_LIST_INTRO})),
            actionSecondary: () => {
                ga('send', 'event', 'general_tutorial', 'skipped');
                this.props.dispatch(updateCurrentStep({step: STEP_COMPLETE}))
            }
        }
    }

    onMessageDataChanged = (updatedData) => {
        this.setState({messageBuilderData: updatedData});
    }

    generateMessage = () => {
        ga('send', 'event', 'gen_generate_message', 'click');
        const messageTemplate = buildGeneralMessageTemplate(this.state.messageBuilderData);
        insertSuggestion(messageTemplate, Office.CoercionType.Html);
        if (this.props.currentOnboardingStep === STEP_GEN_GENERATE_MESSAGE) {
            this.props.dispatch(updateCurrentStep({step: STEP_GEN_PRE_COMPLETE}));
        }
    }

    getCalloutProps = () => {
        switch (this.props.currentOnboardingStep) {
            case STEP_GEN_MAILING_LIST_INTRO:
                    return {
                        description: <span>
                                        Add required interviewers to the To: field and cc the optional interviewers.
                                    </span>,
                        target: this.mailingListRef,
                        action: () => this.props.dispatch(updateCurrentStep({step: STEP_MESSAGE_BUILDER_INTRO}))
                    };
            case STEP_PINNING:
                    return {
                        description: <span>
                                        <h5>Perfect!</h5>Finally, click on the{' '}
                                        <Icon className="button" iconName="Pinned" aria-label="Click to edit" />
                                        {' '}icon above to pin this add-in if you'd like it to open automatically with every new email.
                                    </span>,
                        target: this.pinningTarget,
                        actionText: "Done",
                        action: () => this.props.dispatch(updateCurrentStep({step: STEP_COMPLETE}))
                    };
            case STEP_GEN_GENERATE_MESSAGE:
                return {
                    description: <span><h4>Great!</h4><br/>You’ve got all the details down! Hit this button to populate the message into your email body.</span>,
                    target: this.generateMessageButtonRef
                };
        }
    }

    preCompleteLayoutProps = () => {
        return {
            header: "Awesome!",
            subHeader: "I just populated the message into your email body and cc'd myself",
            welcomeMessage: "You can directly edit the message - otherwise, you're good to go!",
            actionPrimary: () => this.props.dispatch(updateCurrentStep({step: STEP_PINNING})),
            textPrimary: "Next"
        }
    }

    render() {
        const {
            currentOnboardingStep
        } = this.props;

        const canGenerateMessage = true;

        return (
            <React.Fragment>
                {currentOnboardingStep === STEP_INTRO && <EvieLayover layoutData={this.introLayoutProps()} />}
                {currentOnboardingStep === STEP_GEN_PRE_COMPLETE && <EvieLayover layoutData={this.preCompleteLayoutProps()} />}
                {<EvieCallout data={this.getCalloutProps()} />}
                <div className='evie-main'>
                    <div className="pinning-target" ref={this.pinningTarget}></div>
                    <div ref={this.mailingListRef}><MailingListHelp inviteeType={"invitees"} /></div>

                    <GeneralMessageBuilder onMessageDataChanged={this.onMessageDataChanged} />
                    <div ref={this.generateMessageButtonRef} className="generate-message">
                        <PrimaryButton text="Generate Message" onClick={this.generateMessage} disabled={!canGenerateMessage} />
                    </div>

                    <Link className="secondary footer-link" onClick={this.onFaqClick}>Got a question? See our FAQ</Link>
                    <div style={{width: '100%'}}>
                        <Link className="secondary footer-link" onClick={this.showTutorial} style={{marginTop: 5, marginRight: 5}}>
                            Replay Tutorial
                        </Link>
                        <small>|</small>
                        <Link className="secondary footer-link" onClick={signOut} style={{marginTop: 5, marginLeft: 5}}>
                            Sign out
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = function(state) {
    return {
      currentOnboardingStep: state.currentOnboardingStep,
      taskData: state.taskData,
      isNewTask: state.isNewTask,
    }
}

export default connect(mapStateToProps)(GeneralLayout);