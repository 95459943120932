import store from '../store';

const buildCandidateList = (candidatesList) => {
    const canidates = candidatesList.map((candidate) => `<li>${candidate}</li>`);
    return `<ul>${canidates.join("")}</ul>`
}

const buildLocationData = (selectedLocation, shouldBookRoom, lowercase=false) => {
    if (!selectedLocation) return '';
    let prefix = locationPrefix(shouldBookRoom);
    prefix = shouldBookRoom&&lowercase ? prefix.toLowerCase() : prefix;
    return `${prefix} ${selectedLocation}.`;
}

const buildInviteeLocationData = (data) => data ? `${data}.` : '';

export const locationPrefix = (shouldBookRoom) => shouldBookRoom ? "Book a room in" : "Location is";

export const buildRecruitmentMessageTemplate = (messageBuilderData) => {
    const { when, candidates, selectedLocation, shouldBookRoom, hmLocationData} = messageBuilderData;
    const pluralize = candidates.length > 1 ? 's' : '';
    const paragraph1 = `Hi Evie,<br/><br/>Please schedule ${pluralize?' ': 'an '}interview${pluralize} ${when} with the following candidate${pluralize} :<br/>`;

    const paragraph2 = buildCandidateList(candidates);

    let paragraph3 = [
            buildLocationData(selectedLocation, shouldBookRoom),
            buildInviteeLocationData(hmLocationData).replace(/\.\s*\.+/g, ".")
    ].filter(e => e.trim() !== '').join(" ");

    paragraph3 = paragraph3 ?`${paragraph3}<br/>` : '';

    const signatureEnabled = store.getState().includeSignature;

    const signature = signatureEnabled ? `<br/>Regards,<br/>${Office.context.mailbox.userProfile.displayName}`: '';
    return paragraph1 + paragraph2 + paragraph3 + signature;
}

const generateDurationString = (duration, isBlockCalendar) => {
    if(duration == null || duration === '') return '';
    const durationString = `${duration} min`;
    return isBlockCalendar ? `for ${durationString}` : durationString;
}

export const buildGeneralMessageTemplate = (messageBuilderData) => {
    const {
        when,
        duration,
        selectedLocation,
        shouldBookRoom,
        inviteeLocationData,
        intent,
        meetingType,
        callLocation
    } = messageBuilderData;

    const isBlockCalendar = intent.key === "block_calendar";
    const isSendInvite = intent.key === "send_invite";
    const durationString = generateDurationString(duration, isBlockCalendar);
    const meetingTypeString = isBlockCalendar ? '' : ` ${meetingType.text}`;
    const paragraph1 = `Hi Evie,<br/><br/>
                        Please ${intent.text}${isBlockCalendar ? ' ' : ' a '}${durationString}${meetingTypeString}
                        ${isBlockCalendar ? ' at':''}${isSendInvite ? ' on ':' '}${when}`;

    let paragraph2 = [
            buildLocationData(selectedLocation, shouldBookRoom, true),
            buildInviteeLocationData(inviteeLocationData).replace(/\.\s*\.+/g, ".")
    ].filter(e => e.trim() !== '').join(" ");

    paragraph2 = paragraph2 ?`${paragraph2}<br/>` : '';

    const paragraph3 = callLocation ? `<br/>Location is ${callLocation} .<br/>` : '';

    const signatureEnabled = store.getState().includeSignature;

    const signature = signatureEnabled ? `<br/>Regards,<br/>${Office.context.mailbox.userProfile.displayName}`: '';
    const delimiter = (shouldBookRoom&&paragraph2)?' and ':'.<br/>';
    return `${paragraph1}${delimiter}${paragraph2}` + paragraph3 + signature;
}