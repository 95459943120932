import * as API from '../api';
import store from '../store';
import { updateSigninData, updateFetchingUserData, updateCurrentStep, setLoading } from '../actions';
import { STEP_INTRO, STEP_RECRUITMENT_INTRO } from '../constants';

export const fetchUserData = (setSignin=false) => {
    store.dispatch(updateFetchingUserData(true));
    Office.context.mailbox.getUserIdentityTokenAsync((result) => {
        const userToken = result.value;
        API.getUserData(userToken)
        .then(data => onUserDataFetched(data, setSignin))
        .catch(error => onUserDataFetched({success: false, error: error.message, failed: true}));
    });
}

const onUserDataFetched = (data, setSignin) => {
    if (!data.failed) {
        store.dispatch(updateSigninData({isSignedIn: data.success, userData: data.user_data}));
        if(setSignin) {
            if(data.success) {
                const introStep = data.user_data.recruitment_enabled ? STEP_RECRUITMENT_INTRO : STEP_INTRO;
                store.dispatch(updateCurrentStep({step: introStep}));
            }
        }
    }
    store.dispatch(updateFetchingUserData(false));
}

export const signOut = () => {
    ga('send', 'event', 'sign_out', 'click');
    store.dispatch(setLoading({loading: true, message: "Signing Out..."}))
    Office.context.mailbox.getUserIdentityTokenAsync((result) => {
        const userToken = result.value;
        API.signOut(userToken)
        .then(data => postSignOut())
        .catch(error => store.dispatch(setLoading({loading: false})));
    });
}

const postSignOut = () => {
    store.dispatch(updateSigninData({isSignedIn: false}));
    store.dispatch(setLoading({loading: false}))
}