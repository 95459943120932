import * as React from 'react';
import { Link } from 'office-ui-fabric-react/lib/Link';
import Typed from 'typed.js';

export default class EvieLayover extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      typedInitiated: false
    }
  }

  showEvieIntro = (ref, header) => {
    if (!this.state.typedInitiated) {
      var options = {
        strings: [header],
        typeSpeed: 40,
        showCursor: false
      }
      var typed = new Typed(".evie-intro", options);
      this.setState({typedInitiated: true});
    }
  }

  render() {
    const {
      header,
      subHeader,
      welcomeMessage,
      actionPrimary,
      actionSecondary,
      textPrimary,
      textSecondary
    } = this.props.layoutData;

    return (
        <div className="layover">
            <h2 className="welcome-header evie-intro" ref={(ref) => this.showEvieIntro(ref, header)}></h2>
            <p className="sub-header">{subHeader}</p>
            <br></br>
            <br></br>
            <h4 className="welcome-message">{welcomeMessage}</h4>
            <br></br>
            {actionPrimary && (
              <Link onClick={actionPrimary} className="primary">
                  {textPrimary || "Let's go!"}
              </Link>
            )}
            {actionSecondary && (
              <Link onClick={actionSecondary} style={{fontSize: 10}} className="secondary">
                  {textSecondary || "Skip the tour"}
              </Link>
            )}
        </div>
    );
  }
}